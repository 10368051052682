<template>
<div>Buring</div>
</template>

<script>
export default {
	name: 'Buring',
}
</script>

<style scoped>

</style>
